
import { Options, Vue, Watch } from "vue-property-decorator";
import Page from "@views/page/components/page.vue";
import ChartStatistic from "@/views/components/chart-statistic.vue";
import ChartDataModel from "@/views/components/chartDataModel";
import ChartDatasetsModel from "@/views/components/chartDatasetsModel";
import FilterModel from "../components/filter-model";
import GetEndpointsStatsRequest from "@/dataSource/api/LoggerMicroService/Services/Models/Request/EndpointsStatisticsService/GetEndpointsStatsRequest";
import GetEndpointStatisticResponse from "@/dataSource/api/LoggerMicroService/Services/Models/Response/EndpointsStatisticsService/GetEndpointStatisticResponse";
import getUTC from "@/function/getUTC"

@Options({
  name: "LogsStatisticsPage",
  components: {
    Page,
    ChartStatistic,
  },
})
export default class LogsStatisticsPage extends Vue {
  filterModel: FilterModel = new FilterModel();
  isLoading: boolean = false;

  isLiveReload = false;
  timerId = 0;

  error1 = {
    isSuccess: true,
    errorMessage: ""
  }
  error2 = {
    isSuccess: true,
    errorMessage: ""
  }
  error3 = {
    isSuccess: true,
    errorMessage: ""
  }
  error4 = {
    isSuccess: true,
    errorMessage: ""
  }

  datasets1 = new ChartDatasetsModel({
    label: "Количество вызовов методов",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
  });
  datasets2 = new ChartDatasetsModel({
    label: "Время выполнения методов",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
  });
  datasets3 = new ChartDatasetsModel({
    label: "Размер входящего трафика по методам",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
  });
  datasets4 = new ChartDatasetsModel({
    label: "Размер исходящего трафика по методам",
    data: [],
    borderColor: "#fc2586",
    backgroundColor: "#fc2586",
    yAxisID: "y",
    pointStyle: "circle",
  });

  chartModel1 = new ChartDataModel({
    labels: [],
    datasets: [this.datasets1],
  });
  chartModel2 = new ChartDataModel({
    labels: [],
    datasets: [this.datasets2],
  });
  chartModel3 = new ChartDataModel({
    labels: [],
    datasets: [this.datasets3],
  });
  chartModel4 = new ChartDataModel({
    labels: [],
    datasets: [this.datasets4],
  });

  unmounted() {
    clearInterval(this.timerId)
  }

  liveReload(value) {
    this.isLiveReload = value
    if (value) {
      this.timerId = setInterval(() => {
        this.filterModel.DateTo = new FilterModel().DateTo
        this.onclickFilterChange()
      }, 5000)
    } else {
      clearInterval(this.timerId)
    }
  }

  onclickFilterChange() {
    this.GetExecuteMethodsCount();
    this.GetTimeRunMethods();
    this.GetInputTraffic();
    this.GetOutputTraffic();
  }

  async GetTimeRunMethods() {
    this.isLoading = true
    let response: GetEndpointStatisticResponse[];
    let getEndpointsStatsRequest: GetEndpointsStatsRequest = {
      FromDate: this.filterModel.DateFrom,
      ToDate: this.filterModel.DateTo,
      ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
      EndpointId: this.filterModel.EndpointId,
      SessionToken: this.filterModel.SessionToken,
      ConnectionId: this.filterModel.ConnectionId,
      Ip: this.filterModel.Ip,
      UserId: this.filterModel.UserId,
    };
    let tmpFilter = getUTC(getEndpointsStatsRequest)
    try {
      response =
        await this.$api.EndpointsStatisticsService.getExecuteMethodsTimeAsync(
          tmpFilter
        );
    } catch (error) {
      this.error1.isSuccess = false;
      this.error1.errorMessage = error;
      this.isLoading = false;
    }
    this.chartModel1.labels.splice(0);
    this.datasets1.data.splice(0);
    response.forEach((element) => {
      this.chartModel1.labels.push(element.Date.toString());
      this.datasets1.data.push(element.Value);
    });
    this.isLoading = false;
  }

  async GetExecuteMethodsCount() {
    this.isLoading = true
    let getEndpointsStatsRequest: GetEndpointsStatsRequest = {
      FromDate: this.filterModel.DateFrom,
      ToDate: this.filterModel.DateTo,
      ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
      EndpointId: this.filterModel.EndpointId,
      SessionToken: this.filterModel.SessionToken,
      ConnectionId: this.filterModel.ConnectionId,
      Ip: this.filterModel.Ip,
      UserId: this.filterModel.UserId,
    };
    let response: GetEndpointStatisticResponse[];
    let tmpFilter = getUTC(getEndpointsStatsRequest)
    try {
      response =
        await this.$api.EndpointsStatisticsService.getExecuteMethodsCountAsync(
          tmpFilter
        );
    } catch (error) {
      this.error2.isSuccess = false;
      this.error2.errorMessage = error;
      this.isLoading = false;
    }
    this.chartModel2.labels.splice(0);
    this.datasets2.data.splice(0);
    response.forEach((element) => {
      this.chartModel2.labels.push(element.Date.toString());
      this.datasets2.data.push(element.Value);
    });
    this.isLoading = false;
  }

  async GetInputTraffic() {
    this.isLoading = true;
    let getEndpointsStatsRequest: GetEndpointsStatsRequest = {
      FromDate: this.filterModel.DateFrom,
      ToDate: this.filterModel.DateTo,
      ProjectLogGroupId: this.filterModel.ProjectLogGroupId,
      EndpointId: this.filterModel.EndpointId,
      SessionToken: this.filterModel.SessionToken,
      ConnectionId: this.filterModel.ConnectionId,
      Ip: this.filterModel.Ip,
      UserId: this.filterModel.UserId,
    };
    let response: GetEndpointStatisticResponse[];
    let tmpFilter = getUTC(getEndpointsStatsRequest)
    try {
      response =
        await this.$api.EndpointsStatisticsService.getIncomingTrafficSizeAsync(
          tmpFilter
        );
    } catch (error) {
      this.error3.isSuccess = false;
      this.error3.errorMessage = error;
      this.isLoading = false;
    }
    this.chartModel3.labels.splice(0);
    this.datasets3.data.splice(0);
    response.forEach((element) => {
      this.chartModel3.labels.push(element.Date.toString());
      this.datasets3.data.push(element.Value);
    });
    this.isLoading = false;
  }

  async GetOutputTraffic() {
    this.isLoading = true;
    let getEndpointsStatsRequest: GetEndpointsStatsRequest = {
      FromDate: this.filterModel.DateFrom,
      ToDate: this.filterModel.DateTo,
      ProjectLogGroupId: this.filterModel.source,
      EndpointId: this.filterModel.EndpointId,
      SessionToken: this.filterModel.SessionToken,
      ConnectionId: this.filterModel.ConnectionId,
      Ip: this.filterModel.Ip,
      UserId: this.filterModel.UserId,
    };
    let response: GetEndpointStatisticResponse[];
    let tmpFilter = getUTC(getEndpointsStatsRequest)
    try {
      response =
        await this.$api.EndpointsStatisticsService.getOutgoingTrafficSizeAsync(
          tmpFilter
        );
    } catch (error) {
      this.error4.isSuccess = false;
      this.error4.errorMessage = error;
      this.isLoading = false;
    }
    this.chartModel4.labels.splice(0);
    this.datasets3.data.splice(0);
    response.forEach((element) => {
      this.chartModel4.labels.push(element.Date.toString());
      this.datasets4.data.push(element.Value);
    });
    this.isLoading = false;
  }
}
